import React, {Component} from "react";

import UserService from "../../services/userService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {uk} from 'date-fns/locale';
import MainMenuComponent from "../mainMenuComponent";
import {Link} from "react-router-dom";
import moment from "moment-timezone";

export default class Home extends Component {
    constructor(props) {
        super(props);

        const storedDate = localStorage.getItem('selectedDate');
        const timeOfSaveSelectedDate = localStorage.getItem('timeOfSaveSelectedDate');
        const timeToSave = localStorage.getItem('storedTimeToSave');

        const now = new Date();
        const minutesPassed = (now.getTime() - new Date(timeOfSaveSelectedDate).getTime()) / 1000 / 60;
        let startDate;
        if (minutesPassed > timeToSave || !storedDate) {
            startDate = new Date();
        } else {
            startDate = Date(storedDate);
        }

        this.state = {
            content: "",
            departments: "",
            selectedDepartment: '',
            startDate: startDate,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.role_director) {
            // Якщо користувач - директор, встановлюємо його підрозділ як вибраний
            this.setState({selectedDepartment: user.department.id});
            // Виконуємо фільтрацію даних за підрозділом директора
            this.fetchData(this.state.startDate, user.department.id);
        } else {
            // Якщо користувач не директор, виконуємо звичайне завантаження даних
            this.fetchData(this.state.startDate);
        }
    }

    fetchData(date, departmentId) {
        const timestamp = Math.floor(date.getTime() / 1000);
        UserService.getManagerDataForDate(timestamp).then(
            (response) => {
                let data = response.data;

                // Якщо departmentId вказано, фільтруємо дані
                if (departmentId) {
                    data = data.filter(function (item) {
                        return item.department.id == departmentId;
                    });

                }

                data.sort(function (a, b) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1; // nameA йде перед nameB
                    }
                    if (nameA > nameB) {
                        return 1; // nameA йде після nameB
                    }

                    // Імена однакові
                    return 0;
                });

                this.setState({
                    content: data,
                });

            },
            (error) => {
                this.setState({
                    content:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString(),
                });
            }
        );
        UserService.getDepartments().then(
            (response) => {
                this.setState({
                    departments: response.data,
                });
            },
            (error) => {
                this.setState({
                    departments:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString(),
                });
            }
        );
    }

    handleChange(date) {
        this.setState({
            startDate: date,
        });
        localStorage.setItem('selectedDate', date.toISOString());
        localStorage.setItem('timeOfSaveSelectedDate', new Date().toISOString());
        this.fetchData(date, this.state.selectedDepartment);
    }

    handleDepartmentChange(event) {

        const departmentId = event.target.value;

        this.setState({selectedDepartment: departmentId});
        if (departmentId) {
            // Фільтрувати дані за вибраним підрозділом
            this.fetchData(this.state.startDate, departmentId);
        } else {
            // Якщо вибрано "Всі підрозділи", не фільтрувати дані
            this.fetchData(this.state.startDate);
        }
    }


    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const isDirector = user && user.role_director;

        return (

            <div>
                <MainMenuComponent/>

                <div className="p-4 sm:ml-64">
                    <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">

                        <section className="bg-white dark:bg-gray-900">
                            <div className=" py-8 mx-auto lg:py-2">
                                <form action="#" className=" py-8 mx-auto lg:py-2">
                                    <div className="grid gap-4 mb-4 sm:grid-cols-3 sm:gap-4 sm:mb-5">
                                        {!isDirector && (
                                            <div>
                                                <select
                                                    id=" "
                                                    value={this.state.selectedDepartment}
                                                    onChange={this.handleDepartmentChange}
                                                    className="bg-gray-50 font-medium border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                >
                                                    <option value="">Всі підрозділи</option>
                                                    {Array.isArray(this.state.departments) && this.state.departments.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>

                                            </div>)}
                                        <div>
                                            <div className="relative font-medium ">
                                                <div
                                                    className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                         fill="currentColor" viewBox="0 0 20 20">
                                                        <path
                                                            d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"></path>
                                                    </svg>
                                                </div>
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    locale={uk}
                                                    dateFormat="dd.MM.yyyy"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </section>

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead
                                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        МЕНЕДЖЕР
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ПОЧАТОК МАРШРУТУ
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ТТ ПЛАН
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ТТ ФАКТ
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ЗАМОВЛЕННЯ
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ІНКАСАЦІЯ
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        КІНЕЦЬ МАРШРУТУ
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {Array.isArray(this.state.content) && this.state.content.map((item, index) => (
                                    <tr key={index}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row"
                                            className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                            <div className="pl-1">
                                                <div className="text-base font-semibold">
                                                    <Link to={`/route_details/${item.id}`}>{item.name}</Link>
                                                </div>
                                                <div className="font-normal text-gray-500">{item.email}</div>
                                            </div>
                                        </th>
                                        <td className="px-6 py-4">
                                            {item.start > 0 ? (
                                                <span
                                                    className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">

                                                    {
                                                        moment(item.start * 1000).tz('Europe/Kiev').format('YYYY-MM-DD HH:mm:ss')
                                                    }

                                                    {/*{new Date(item.start * 1000).toLocaleDateString('uk-UA', {*/}
                                                    {/*    year: 'numeric',*/}
                                                    {/*    month: '2-digit',*/}
                                                    {/*    day: '2-digit',*/}
                                                    {/*})}{' '}*/}
                                                    {/*    {new Date(item.start * 1000).toLocaleTimeString('uk-UA', {*/}
                                                    {/*        hour: '2-digit',*/}
                                                    {/*        minute: '2-digit',*/}
                                                    {/*        second: '2-digit',*/}
                                                    {/*    })}*/}
                                            </span>
                                            ) : (
                                                <span
                                                    className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                                Дані відсутні
                                            </span>)}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <div className="font-medium">{item.plan_count}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <div className="font-medium">{item.visits_count}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <div className="font-medium">{item.orders_count}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <div className="font-medium">{item.collection_count}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.end > 0 ? (<span
                                                className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                {
                                                    moment(item.end * 1000).tz('Europe/Kiev').format('YYYY-MM-DD HH:mm:ss')
                                                }
                                            </span>) : (<span
                                                className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                                Дані відсутні
                                            </span>)}
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>);
    }
}
