import React, {useEffect, useRef, useState, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {GoogleMap, LoadScript, Marker, InfoWindow, DirectionsService, DirectionsRenderer} from '@react-google-maps/api';
import UserService from "../../services/userService";
import yellowMarker from "../../assets/yellow.png";
import redMarker from "../../assets/red.png";
import blueMarker from "../../assets/blue.png";
import MainMenuComponent from "../mainMenuComponent";
import "../../config";
import config from "../../config";


// Бібліотеки, які використовуються для Google Maps API
const librariesFact = ['places'];
const librariesPlan = ['places'];

const MapComponent = () => {
    // Використовуємо хук useLocation для отримання даних з роутера
    const location = useLocation();
// Деструктуризуємо об'єкт location, щоб отримати managerId і startDate
    const {managerId, startDate} = location.state;
// Ініціалізуємо стан для зберігання фактичних даних маршруту
    const [routeFactData, setRouteFactData] = useState([]);
// Ініціалізуємо стан для зберігання планованих даних маршруту
    const [routePlanData, setRoutePlanData] = useState([]);
// Ініціалізуємо стан для зберігання вибраної точки на мапі
    const [selectedPoint, setSelectedPoint] = useState(null);

// Ініціалізуємо референт для зберігання посилання на об'єкт GoogleMap
    const mapRef = useRef(null);

// Ініціалізуємо стан для зберігання відповіді сервісу DirectionsService для фактичного маршруту
    const [directionsResponseFact, setDirectionsResponseFact] = useState(null);
// Ініціалізуємо стан для зберігання відповіді сервісу DirectionsService для планованого маршруту
    const [directionsResponsePlan, setDirectionsResponsePlan] = useState(null);

// Ініціалізуємо стан для визначення відображення фактичного маршруту
    const [showFactRoute, setShowFactRoute] = useState(true);
// Ініціалізуємо стан для визначення відображення планованого маршруту
    const [showPlanRoute, setShowPlanRoute] = useState(true);

// Ініціалізуємо референт для зберігання центральної точки мапи
    const mapCenterRef = useRef({lat: 50.441672685980116, lng: 30.554122025148565});


    // Змінює стан відображення фактичного маршруту
    const handleFactRouteChange = () => {
        setShowFactRoute(!showFactRoute);
    };

    // Змінює стан відображення планованого маршруту
    const handlePlanRouteChange = () => {
        setShowPlanRoute(!showPlanRoute);
    };

    // Завантажує дані при зміні managerId або startDate
    useEffect(() => {
        fetchData(managerId, startDate);
    }, [managerId, startDate]);

    // Завантажує дані з сервера
    const fetchData = (managerId, date) => {
        const timestamp = Math.floor(date.getTime() / 1000);
        // Завантажує фактичний маршрут
        UserService.getRouteFact(managerId, timestamp).then(
            (response) => {
                const data = response.data;
                const chunkSize = 25;
                const dataChunks = [];

                for (let i = 0; i < data.length; i += chunkSize) {
                    let chunk = data.slice(i, i + chunkSize);
                    dataChunks.push(chunk);
                }

                //console.log('data chunks: --- ',dataChunks);
                setRouteFactData(dataChunks);
                calculateRouteFact(dataChunks);

            },
            (error) => {
                console.log(
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString(),
                );
            }
        );
        // Завантажує планований маршрут
        UserService.getRoutePlan(managerId, timestamp).then(
            (response) => {
                const data = response.data;
                const chunkSize = 25;
                const dataChunks = [];

                for (let i = 0; i < data.length; i += chunkSize) {
                    let chunk = data.slice(i, i + chunkSize);
                    dataChunks.push(chunk);
                }

                //console.log('data chunks: --- ',dataChunks);
                setRoutePlanData(dataChunks);
                calculateRoutePlan(dataChunks);

            },
            (error) => {
                console.log(
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString(),
                );
            }
        );
    };

    // Розраховує фактичний маршрут
    const calculateRouteFact = async (routeFactData) => {
        if (!Array.isArray(routeFactData) || routeFactData.length < 1) return;

        const responses = await Promise.all(
            routeFactData.map(async (chunk) => {
                if (chunk.length < 2) return null;

                const waypoints = chunk.map((point) => ({
                    location: {lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)},
                    stopover: true,
                }));

                const origin = waypoints[0].location;
                const destination = waypoints[waypoints.length - 1].location;

                return new Promise((resolve, reject) => {
                    const directionsService = new window.google.maps.DirectionsService();
                    directionsService.route(
                        {
                            origin,
                            destination,
                            waypoints: waypoints.slice(1, -1),
                            travelMode: 'DRIVING',
                        },
                        (result, status) => {
                            if (status === 'OK') {
                                console.log(result);
                                resolve(result);
                            } else {
                                //reject('Failed to fetch directions');
                            }
                        }
                    );
                });
            })
        );

        console.log('google response: ',responses);
        setDirectionsResponseFact(responses);
    };


    // Розраховує планований маршрут
    const calculateRoutePlan = async (routePlanData) => {
        if (!Array.isArray(routePlanData) || routePlanData.length < 1) return;

        const responses = await Promise.all(
            routePlanData.map(async (chunk) => {
                if (chunk.length < 2) return null;

                const waypoints = chunk.map((point) => ({
                    location: {lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)},
                    stopover: true,
                }));

                const origin = waypoints[0].location;
                const destination = waypoints[waypoints.length - 1].location;

                return new Promise((resolve, reject) => {
                    const directionsService = new window.google.maps.DirectionsService();
                    directionsService.route(
                        {
                            origin,
                            destination,
                            waypoints: waypoints.slice(1, -1),
                            travelMode: 'DRIVING',
                        },
                        (result, status) => {
                            if (status === 'OK') {
                                console.log(result);
                                resolve(result);
                            } else {
                                //reject('Failed to fetch directions');
                            }
                        }
                    );
                });
            })
        );

        setDirectionsResponsePlan(responses);
    };


    // Обробляє відповідь для фактичного маршруту
    const handleDirectionsResponseFact = (response, status) => {
        if (response.status === 'OK') {
            setDirectionsResponseFact(response);
        } else {
            console.error('error fetching directions', response);
        }
    };


    // Обробляє відповідь для планованого маршруту
    const handleDirectionsResponsePlan = (response, status) => {
        if (response.status === 'OK') {
            setDirectionsResponsePlan(response);
        } else {
            console.error('error fetching directions', response);
        }
    };

    return (
        <div>
            <MainMenuComponent/>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <div className="grid gap-4 mb-4 sm:grid-cols-3 sm:gap-4 sm:mb-5">
                        <div>
                            <input
                                type="checkbox"
                                checked={showFactRoute}
                                onChange={handleFactRouteChange}
                            />
                            <label>Відображати фактичний маршрут</label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                checked={showPlanRoute}
                                onChange={handlePlanRouteChange}
                            />
                            <label>Відображати плановий маршрут</label>
                        </div>
                    </div>

                    <div style={{height: '80vh', width: '100%'}}>
                        <LoadScript
                            googleMapsApiKey= {config.GOOGLE_MAPS_API_KEY}
                            libraries={librariesFact}
                        >
                            <GoogleMap
                                mapContainerStyle={{width: '100%', height: '100%'}}
                                center={mapCenterRef.current}
                                onLoad={(map) => {
                                    mapRef.current = map;
                                }}
                                onCenterChanged={() => {
                                    if (mapRef.current) {
                                        const center = mapRef.current.getCenter();
                                        mapCenterRef.current = {
                                            lat: center.lat(),
                                            lng: center.lng(),
                                        };
                                    }
                                }}
                                zoom={8}
                                options={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                }}
                            >
                                <RenderDirections
                                    directionsResponsePlans={directionsResponsePlan}
                                    showPlanRoute={showPlanRoute}
                                    handleDirectionsResponsePlan={handleDirectionsResponsePlan}
                                    setDirectionsResponsePlan={setDirectionsResponsePlan}
                                    routePlanData={routePlanData}
                                    setSelectedPoint={setSelectedPoint}
                                    selectedPoint={selectedPoint}
                                />
                                <RenderDirectionsFact
                                    directionsResponseFacts={directionsResponseFact}
                                    showFactRoute={showFactRoute}
                                    handleDirectionsResponseFact={handleDirectionsResponseFact}
                                    setDirectionsResponseFact={setDirectionsResponseFact}
                                    routeFactData={routeFactData}
                                    setSelectedPoint={setSelectedPoint}
                                    selectedPoint={selectedPoint}
                                />


                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </div>
        </div>
    );
};

function RenderDirections({
                              directionsResponsePlans,
                              showPlanRoute,
                              handleDirectionsResponsePlan,
                              setDirectionsResponsePlan, routePlanData, setSelectedPoint, selectedPoint
                          }) {
    const [responses, setResponses] = useState([]);

    useEffect(() => {
        if (directionsResponsePlans != null) {
            if (responses.length === directionsResponsePlans.length) {
                setDirectionsResponsePlan(responses);
            }
        }
    }, [responses, directionsResponsePlans]);

    const handleResponse = (response, index) => {

        setResponses(prevResponses => {
            const newResponses = [...prevResponses];
            newResponses[index] = response;
            return newResponses;
        });
        handleDirectionsResponsePlan(response, index);

    };

    const directionsResponses = useMemo(() => {
        return responses.filter(response => response != null);
    }, [responses]);

    const elements = [];
    if (directionsResponsePlans) {
        for (let i = 0; i < directionsResponsePlans.length; i++) {
            let plan = directionsResponsePlans[i];
            elements.push(
                <div key={i}>
                    {showPlanRoute && plan && (
                        <DirectionsService
                            options={plan}
                            callback={(response, status) => {
                                console.log('DirectionsService response:', response);
                                console.log('DirectionsService status:', status);
                                if (status === 'OK') {
                                    handleResponse(response, i);
                                } else {
                                    console.error('Помилка при отриманні відповіді від DirectionsService:', status);
                                }
                            }}
                        />
                    )}


                    {showPlanRoute && plan && (
                        <DirectionsRenderer
                            directions={plan}
                            options={{
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: '#ff2e6d',
                                    strokeOpacity: 0.5,
                                    strokeWeight: 5,
                                },
                            }}
                        />
                    )}

                    {showPlanRoute && plan && (
                        routePlanData[i].map((point, index) => (

                            <Marker
                                key={index}
                                position={{lat: Number(point.latitude), lng: Number(point.longitude)}}
                                label={{text: String(index + 1), color: 'black'}}
                                icon={{
                                    url: yellowMarker,
                                    scaledSize: new window.google.maps.Size(25, 35),
                                    labelOrigin: new window.google.maps.Point(12.5, 13)
                                }}
                                onClick={() => setSelectedPoint(point)}
                            />
                        ))
                    )}
                    {showPlanRoute && plan && (selectedPoint && (
                        <InfoWindow
                            position={{lat: Number(selectedPoint.latitude), lng: Number(selectedPoint.longitude)}}
                            onCloseClick={() => setSelectedPoint(null)}
                        >
                            <div>
                                <p>{(String(selectedPoint.name) === 'null') ? 'Коригування маршруту' : String(selectedPoint.name)}</p>
                            </div>
                        </InfoWindow>
                    ))}
                </div>
            );

        }
    }
    return <>{elements}</>;
}

function RenderDirectionsFact({
                                  directionsResponseFacts,
                                  showFactRoute,
                                  handleDirectionsResponseFact,
                                  setDirectionsResponseFact, routeFactData, setSelectedPoint, selectedPoint
                              }) {
    const [responses, setResponses] = useState([]);

    useEffect(() => {
        if (directionsResponseFacts != null) {
            if (responses.length === directionsResponseFacts.length) {
                setDirectionsResponseFact(responses);
            }
        }
    }, [responses, directionsResponseFacts]);

    const handleResponse = (response, index) => {

        setResponses(prevResponses => {
            const newResponses = [...prevResponses];
            newResponses[index] = response;
            return newResponses;
        });
        handleDirectionsResponseFact(response, index);

    };

    const directionsResponses = useMemo(() => {
        return responses.filter(response => response != null);
    }, [responses]);

    const elements = [];

    if (directionsResponseFacts) {
        for (let i = 0; i < directionsResponseFacts.length; i++) {
            let fact = directionsResponseFacts[i];
            console.log('fact',fact)
            elements.push(
                <div key={'9' + i}>
                    {showFactRoute && fact && (
                        <DirectionsService
                            options={fact}
                            callback={(response, status) => {
                                console.log('DirectionsService response:', response);
                                console.log('DirectionsService status:', status);
                                if (status === 'OK') {
                                    handleResponse(response, i);
                                } else {
                                    console.error('Помилка при отриманні відповіді від DirectionsService:', status);
                                }
                            }}
                        />
                    )}


                    {showFactRoute && fact && (
                        <DirectionsRenderer
                            directions={fact}
                            options={{
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: '#0070ff',
                                    strokeOpacity: 0.5,
                                    strokeWeight: 5,
                                },
                            }}
                        />
                    )}

                    {showFactRoute && fact && (
                        routeFactData[i].map((point, index) => (

                            <Marker
                                key={index}
                                position={{lat: Number(point.latitude), lng: Number(point.longitude)}}
                                label={{text: String(index + 1), color: 'white'}}
                                icon={{
                                    url: (String(point.name) === 'null') ? redMarker : blueMarker,
                                    scaledSize: new window.google.maps.Size(25, 35),
                                    labelOrigin: new window.google.maps.Point(12.5, 13)
                                }}
                                onClick={() => setSelectedPoint(point)}
                            />
                        ))
                    )}
                    {showFactRoute && fact && (selectedPoint && (
                        <InfoWindow
                            position={{lat: Number(selectedPoint.latitude), lng: Number(selectedPoint.longitude)}}
                            onCloseClick={() => setSelectedPoint(null)}
                        >
                            <div>
                                <p>{(String(selectedPoint.name) === 'null') ? 'Коригування маршруту' : String(selectedPoint.name)}</p>
                            </div>
                        </InfoWindow>
                    ))}
                </div>
            );

        }
    }
    return <>{elements}</>;
}


export default MapComponent;