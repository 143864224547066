import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './index.css';
import axios from 'axios';
import NProgress from 'nprogress';
import ErrorBoundary from "./ErrorBoundary";

const container = document.getElementById("root");
const root = createRoot(container);

// Налаштуйте глобальний обробник запитів
axios.interceptors.request.use((config) => {
    NProgress.start();
    return config;
}, (error) => {
    ErrorBoundary.sendMessageToTelegram(error);
    NProgress.done();
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    NProgress.done();
    return response;
}, (error) => {
    ErrorBoundary.sendMessageToTelegram(error);
    NProgress.done();
    return Promise.reject(error);
});

window.addEventListener('error', (event) => {
    ErrorBoundary.sendMessageToTelegram(event.error);
});

window.addEventListener('unhandledrejection', (event) => {
    ErrorBoundary.sendMessageToTelegram(event.reason);
});

root.render(
  <BrowserRouter>
      <ErrorBoundary>
          <App />
      </ErrorBoundary>
  </BrowserRouter>
);

serviceWorker.unregister();
