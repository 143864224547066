import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import UserService from '../../services/userService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {uk} from 'date-fns/locale';
import MainMenuComponent from '../mainMenuComponent';
import 'react-tabs/style/react-tabs.css';
import HeadBodyComponent from "../general/headBody";
import moment from "moment-timezone";
import config from '../../config';
import {utcToZonedTime} from "date-fns-tz";
import {endOfDay, startOfDay} from "date-fns";


const DistanceReportComponent = () => {
    const {managerId} = useParams();
    const [reportData, setReportData] = useState([]);
    let [managerName, setManagerName] = useState('');
    const storedDateStart = localStorage.getItem('selectedDateStart');
    const storedDateEnd = localStorage.getItem('selectedDateEnd');
    const [startDate, setStartDate] = useState(storedDateStart ? new Date(storedDateStart) : new Date());
    const [endDate, setEndDate] = useState(storedDateEnd ? new Date(storedDateEnd) : new Date());
    const [routeFactData, setRouteFactData] = useState([]);
    const [checkIns, setCheckIns] = useState([]);
    const [routePlanData, setRoutePlanData] = useState([]);


    const [shouldDisplay, setShouldDisplay] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        fetchData();
    }, [managerId, startDate, endDate]);

    const fetchData = () => {
        setShouldDisplay(false);

        if (startDate && endDate) {
            let startTimestamp = new Date((startDate.getTime()) - (startDate.getTimezoneOffset() * 60 * 1000)).getTime();
            startTimestamp = startTimestamp / 1000;


            let endTimestamp = new Date((endDate.getTime()) - (endDate.getTimezoneOffset() * 60 * 1000)).getTime();
            endTimestamp = endTimestamp / 1000;

            UserService.getDataReport(managerId, startTimestamp, endTimestamp).then(
                (response) => {
                    let routeFactData = [];
                    let routePlanData = [];
                    let pointsFactData = [];
                    let pointsPlanData = [];
                    let factRoutes = {};
                    let planRoutes = {};
                    let checkIns = [];
                    let checkInsOutPlan = [];

                    let combinedData = [];
                    let combinedPlanData = [];
                    if (response.data.status === "OK") {
                        let forCalculate =response.data.for_calculate;
                        for (let i =0; i<forCalculate.length;i++){
                            factRoutes = JSON.parse(forCalculate[i].fact_route.direction_response);
                            pointsFactData = forCalculate[i].fact_points;
                            planRoutes = JSON.parse(forCalculate[i].fact_route.direction_response);
                            pointsPlanData = forCalculate[i].plan_points;
                            for (let i = 0; i < factRoutes.length; i++) {
                                let legs = factRoutes[i].routes[0].legs;
                                routeFactData = routeFactData.concat(legs);
                            }
                            for (let i = 0; i < planRoutes.length; i++) {
                                if (planRoutes[i].routes.length > 0) {
                                    let legs = planRoutes[i].routes[0].legs;
                                    routePlanData = routePlanData.concat(legs);
                                }
                            }

                            let prevLocation = {
                                'lng': 0,
                                'ltg': 0,
                            }
                            let combinedDataLoc = pointsFactData.map(point => {
                                const correspondingRoute = routeFactData.find(route =>
                                    route.start_location.lat === point.lat && route.start_location.lng === point.lng
                                );
                                let distance = 0;
                                let duration = 0;
                                let prevDistance = calculateDistance(prevLocation.ltg, prevLocation.lng, point.latitude, point.longitude);
                                if (prevDistance > 100 && prevDistance < 2000000) {
                                    for (let j = 0; j < routeFactData.length; j++) {
                                        let roundedDistance = calculateDistance(point.latitude, point.longitude, routeFactData[j].end_location.lat, routeFactData[j].end_location.lng);

                                        if (roundedDistance < 50) {
                                            distance = routeFactData[j].distance.value;
                                            duration = routeFactData[j].duration.value;
                                            break;
                                        }
                                    }
                                    if (distance === 0) {
                                        distance = prevDistance;
                                        duration = 0;
                                    }
                                }
                                prevLocation = {
                                    'lng': point.longitude,
                                    'ltg': point.latitude,
                                };
                                if (point.client) {
                                    let checkIn = {
                                        date: point.date,
                                        delivery_point_id: point.delivery_point_id,
                                        start: point.start,
                                        end: point.end,
                                        name: point.name,
                                        distance: distance,
                                        duration: duration,
                                        client: point.client,
                                    }
                                    checkIns.push(checkIn);
                                }
                                return {
                                    date: point.date,
                                    delivery_point_id: point.delivery_point_id,
                                    start: point.start,
                                    end: point.end,
                                    name: point.name,
                                    distance: distance,
                                    duration: duration,
                                    client: point.client,

                                }

                            });

                            for (let i = 0; i < combinedDataLoc.length; i++) {

                                combinedData.push(combinedDataLoc[i]);
                            }

                            let combinedPlanDataLoc = pointsPlanData.map(point => {
                                let distance = 0;
                                let duration = 0;
                                for (let j = 0; j < routePlanData.length; j++) {
                                    let roundedDistance = calculateDistance(point.latitude, point.longitude, routePlanData[j].end_location.lat, routePlanData[j].end_location.lng);

                                    if (roundedDistance < 50) {
                                        distance = routePlanData[j].distance.value;
                                        duration = routePlanData[j].duration.value;
                                    }
                                }

                                return {
                                    date: point.date,
                                    delivery_point_id: point.delivery_point_id,
                                    start: point.start,
                                    end: point.end,
                                    name: point.name,
                                    distance: distance,
                                    duration: duration,
                                }

                            });
                            for (let i = 0; i < combinedPlanDataLoc.length; i++) {
                                combinedPlanData.push(combinedPlanDataLoc[i]);
                            }
                            prevLocation = {
                                'lng': 0,
                                'ltg': 0,
                            }
                        }


                        managerName = response.data.manager.name;

                        //setReportData(data);
                        setRouteFactData(combinedData);
                        setRoutePlanData(combinedPlanData);
                        setManagerName(managerName);
                        setCheckIns(checkIns);

                        setManagerName(response.data.manager.name);
                    }
                    setTimeout(() => {
                        setShouldDisplay(true);
                    }, 500);


                },
                (error) => {
                    console.log(
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString(),
                    );
                },
            );
        }

    };
    const handleChangeStart = (date) => {
        localStorage.setItem('selectedDateStart',date.toISOString());
        setStartDate(date);
    };

    const handleChangeEnd = (date) => {
        localStorage.setItem('selectedDateEnd',date.toISOString());
        setEndDate(date);
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Радіус Землі в кілометрах
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceKm = R * c; // Відстань в кілометрах
        const distanceMeters = distanceKm * 1000; // Переведення в метри

        return distanceMeters;
    }

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    return (
        <div>
            <MainMenuComponent/>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <section className="bg-white dark:bg-gray-900">
                        <div className="py-8 mx-auto lg:py-2">
                            <form action="#" className="py-8 mx-auto lg:py-2">
                                <div className="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-4 sm:mb-5">
                                    <div>
                                        <div className="relative font-medium ">
                                            <HeadBodyComponent managerName={managerName} backText="Назад"
                                                               routeName={'/list_distance_report'}/>
                                            <div
                                                className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">

                                            </div>
                                            Період з:
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleChangeStart}
                                                className="bg-gray-50 border border-grastart buildy-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                locale={uk}
                                                dateFormat="dd.MM.yyyy"
                                            />
                                            по:
                                            <DatePicker
                                                selected={endDate}
                                                onChange={handleChangeEnd}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                locale={uk}
                                                dateFormat="dd.MM.yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                    <div className="pl-1">

                        {shouldDisplay ? (
                            <div>
                                {/*<p className="pl-2 font-medium text-gray-800 dark:text-white">План: {(() => {*/}
                                {/*    let allDistancePlan = 0;*/}
                                {/*    for (let i = 0; i < routePlanData.length; i++) {*/}

                                {/*        allDistancePlan = allDistancePlan + routePlanData[i].distance;*/}
                                {/*        console.log('distance - ' + i,routePlanData[i]);*/}
                                {/*    }*/}
                                {/*    return (allDistancePlan / 1000).toFixed(3);*/}
                                {/*})()} КМ</p>*/}
                                <p className="pl-2 font-medium text-gray-800 dark:text-white">Факт: {(() => {
                                    let allDistanceFact = 0;
                                    for (let i = 1; i < routeFactData.length; i++) {
                                        allDistanceFact = allDistanceFact + routeFactData[i].distance;
                                    }
                                    return (allDistanceFact / 1000).toFixed(3);
                                })()} КМ</p>
                            </div>
                        ) : (
                            <div role="status">
                                <svg aria-hidden="true"
                                     className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )
                        }

                    </div>
                </div>
            </div>
        </div>

    );

};
export default DistanceReportComponent;
