import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import UserService from '../../services/userService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {uk} from 'date-fns/locale';
import MainMenuComponent from '../mainMenuComponent';
import 'react-tabs/style/react-tabs.css';
import HeadBodyComponent from "../general/headBody";

class ListDistanceReportComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            departments: "",
            selectedDepartment: '',
        };


        this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.role_director) {
            // Якщо користувач - директор, встановлюємо його підрозділ як вибраний
            this.setState({ selectedDepartment: user.department.id });
            // Виконуємо фільтрацію даних за підрозділом директора
            this.fetchData(user.department.id);
        } else {
            // Якщо користувач не директор, виконуємо звичайне завантаження даних
            this.fetchData();
        }
    }

    fetchData(departmentId) {
        UserService.getManagerDataForDate().then(
            (response) => {
                let data = response.data;

                // Якщо departmentId вказано, фільтруємо дані
                if (departmentId) {
                    data = data.filter(function(item) {
                        return item.department.id == departmentId;
                    });

                }

                data.sort(function(a, b) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1; // nameA йде перед nameB
                    }
                    if (nameA > nameB) {
                        return 1; // nameA йде після nameB
                    }

                    // Імена однакові
                    return 0;
                });

                this.setState({
                    content: data,
                });

            },
            (error) => {
                this.setState({
                    content:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString(),
                });
            }
        );
        UserService.getDepartments().then(
            (response) => {
                this.setState({
                    departments: response.data,
                });
            },
            (error) => {
                this.setState({
                    departments:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString(),
                });
            }
        );
    }

    handleDepartmentChange(event) {

        const departmentId = event.target.value;

        this.setState({ selectedDepartment: departmentId });
        if (departmentId) {
            // Фільтрувати дані за вибраним підрозділом
            this.fetchData(departmentId);
        } else {
            // Якщо вибрано "Всі підрозділи", не фільтрувати дані
            this.fetchData();
        }
    }


    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const isDirector = user && user.role_director;

        return (

            <div>
                <MainMenuComponent/>

                <div className="p-4 sm:ml-64">
                    <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">

                        <section className="bg-white dark:bg-gray-900">
                            <div className=" py-8 mx-auto lg:py-2">
                                <form action="#" className=" py-8 mx-auto lg:py-2">
                                    <div className="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-4 sm:mb-5">
                                        {!isDirector && (
                                            <div>
                                                <select
                                                    id=" "
                                                    value={this.state.selectedDepartment}
                                                    onChange={this.handleDepartmentChange}
                                                    className="bg-gray-50 font-medium border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                >
                                                    <option value="">Всі підрозділи</option>
                                                    {Array.isArray(this.state.departments) && this.state.departments.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>

                                            </div>)}


                                    </div>
                                </form>
                            </div>
                        </section>

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead
                                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        МЕНЕДЖЕР
                                    </th>

                                </tr>
                                </thead>

                                <tbody>
                                {Array.isArray(this.state.content) && this.state.content.map((item, index) => (
                                    <tr key={index}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row"
                                            className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                            <div className="pl-1">
                                                <div className="text-base font-semibold">
                                                    <Link to={`/distance_report/${item.id}`}>{item.name}</Link>
                                                </div>
                                                <div className="font-normal text-gray-500">{item.email}</div>
                                            </div>
                                        </th>

                                    </tr>))}
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>);
    }
}

export default ListDistanceReportComponent;