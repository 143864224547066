import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const HeadBodyComponent = (props) => {
    let {
        managerId,
        managerName,
        startDate,
        backText,
        routeName,
    } = props;

    const navigate = useNavigate();


    const handleBack = () => {
        navigate(routeName, {
            state: {
                managerId: managerId,
                startDate: startDate,
            },
        });
    };


    return (
        <>
            <div className="block max-w p-0 bg-white border border-gray-200 rounded-lg shadow mb-6">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    <button type="button" className="text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700
                                                                            text-sm
                                                                            text-center
                                                                            inline-flex
                                                                            items-center
                                                                            me-2
                                                                            mb-2"
                            onClick={handleBack}>
                        <svg className="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                        </svg>
                        {backText}
                    </button>
                    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                        <li className={"px-5"}>
                            {managerName}
                        </li>

                    </ul>
                </div>
            </div>


        </>

    );
};
export default HeadBodyComponent;