import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        ErrorBoundary.sendMessageToTelegram(error, errorInfo);
    }

    static sendMessageToTelegram(error, errorInfo) {
        ErrorBoundary.sendErrorToTelegram(error, errorInfo);
    }

    static sendErrorToTelegram(error, errorInfo) {
        const token = '5805768924:AAGgwAlYSlrXHR_MUzYp_TQ19KoBqY8zeTg';
        const chatId = '422799222';
        let message = `<b>Director monitor error</b>\nError: ${error.toString()}\n<b>Info:</b>\n<pre><code>${error.stack}</code></pre>`;

        
        fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parse_mode: "html",
                chat_id: chatId,
                text: message,
            }),
        })
            .catch(console.error);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }

}

export default ErrorBoundary;
