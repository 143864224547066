import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import UserService from '../../services/userService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {uk} from 'date-fns/locale';
import MainMenuComponent from '../mainMenuComponent';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HeadBodyComponent from "../general/headBody";
import moment from "moment-timezone";
import {startOfDay} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

const BackUpRouteDetailsComponent = () => {
    const {managerId} = useParams();
    let [managerName, setManagerName] = useState('');
    const [routeFactData, setRouteFactData] = useState([]);
    const [routePlanData, setRoutePlanData] = useState([]);
    const [pointsPlanData, setPointsPlanData] = useState([]);
    const [checkIns, setCheckIns] = useState([]);
    const [checkInsOutPlan, setCheckInsOutPlan] = useState([]);
    //const storedDate = localStorage.getItem('selectedDate');
    //const timeOfSaveSelectedDate = localStorage.getItem('timeOfSaveSelectedDate');
    const [startDate, setStartDate] = useState(getStartDate());
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate();

    function getStartDate() {
        const storedDate = localStorage.getItem('selectedDate');
        const timeOfSaveSelectedDate = localStorage.getItem('timeOfSaveSelectedDate');
        const timeToSave = localStorage.getItem('storedTimeToSave');

        const now = new Date();
        const minutesPassed = (now.getTime() - new Date(timeOfSaveSelectedDate).getTime()) / 1000 / 60;
        if (minutesPassed > timeToSave || !storedDate) {
            return new Date();
        }else{
            return new Date(storedDate);
        }
    }

    useEffect(() => {
        if (managerId) {
            fetchData();
        }
    }, [managerId,
        startDate
    ]);

    const fetchData = () => {
        let timestamp = new Date((startDate.getTime()) - (startDate.getTimezoneOffset() * 60 * 1000)).getTime();

        timestamp = Math.round(timestamp / 1000);

        UserService.getRoutes(managerId, timestamp).then(
            (response) => {
                let routeFactData = [];
                let routePlanData = [];
                let pointsFactData = [];
                let pointsPlanData = [];
                let factRoutes = {};
                let planRoutes = {};
                let checkIns = [];
                let checkInsOutPlan = [];
                if (response.data.status === "OK") {
                    if (response.data.routes.fact !== null) {
                        factRoutes = JSON.parse(response.data.routes.fact.direction_response);
                        pointsFactData = response.data.points.fact;
                        for (let i = 0; i < factRoutes.length; i++) {
                            let legs = factRoutes[i].routes[0].legs;
                            routeFactData = routeFactData.concat(legs);
                        }
                    }

                    if (response.data.routes.plan !== null) {
                        planRoutes = JSON.parse(response.data.routes.plan.direction_response);
                        pointsPlanData = response.data.points.plan;
                        for (let i = 0; i < planRoutes.length; i++) {
                            if (planRoutes[i].routes.length > 0) {
                                let legs = planRoutes[i].routes[0].legs;
                                routePlanData = routePlanData.concat(legs);
                            }
                        }
                    }
                    managerName = response.data.manager.name;
                }

                let prevLocation = {
                    'lng': 0,
                    'ltg': 0,
                }
                const combinedData = pointsFactData.map(point => {
                    const correspondingRoute = routeFactData.find(route =>
                        route.start_location.lat === point.lat && route.start_location.lng === point.lng
                    );
                    let distance = 0;
                    let duration = 0;
                    let prevDistance = calculateDistance(prevLocation.ltg, prevLocation.lng, point.latitude, point.longitude);
                    if (prevDistance > 100) {
                        for (let j = 0; j < routeFactData.length; j++) {
                            let roundedDistance = calculateDistance(point.latitude, point.longitude, routeFactData[j].end_location.lat, routeFactData[j].end_location.lng);

                            if (roundedDistance < 50) {
                                distance = routeFactData[j].distance.value;
                                duration = routeFactData[j].duration.value;
                                break;
                            }
                        }
                        if (distance === 0) {
                            distance = prevDistance;
                            duration = 0;
                        }
                    }
                    prevLocation = {
                        'lng': point.longitude,
                        'ltg': point.latitude,
                    };
                    if (point.client) {
                        let checkIn = {
                            date: point.date,
                            delivery_point_id: point.delivery_point_id,
                            start: point.start,
                            end: point.end,
                            name: point.name,
                            distance: distance,
                            duration: duration,
                            client: point.client,
                        }
                        checkIns.push(checkIn);
                    }
                    return {
                        date: point.date,
                        delivery_point_id: point.delivery_point_id,
                        start: point.start,
                        end: point.end,
                        name: point.name,
                        distance: distance,
                        duration: duration,
                        client: point.client,

                    }

                });

                const combinedPlanData = pointsPlanData.map(point => {
                    const correspondingRoute = routePlanData.find(route =>
                        route.start_location.lat === point.lat && route.start_location.lng === point.lng
                    );
                    let distance = 0;
                    let duration = 0;
                    for (let j = 0; j < routePlanData.length; j++) {
                        let roundedDistance = calculateDistance(point.latitude, point.longitude, routePlanData[j].end_location.lat, routePlanData[j].end_location.lng);

                        if (roundedDistance < 50) {
                            distance = routePlanData[j].distance.value;
                            duration = routePlanData[j].duration.value;
                        }
                    }

                    return {
                        date: point.date,
                        delivery_point_id: point.delivery_point_id,
                        start: point.start,
                        end: point.end,
                        name: point.name,
                        distance: distance,
                        duration: duration,
                    }

                });
                checkIns.map((item, index) => {
                    const correspondingRoute = combinedPlanData.find(route =>
                        route.delivery_point_id === item.delivery_point_id
                    );
                    if (!correspondingRoute) {
                        checkInsOutPlan.push(item);
                    }
                });
                setCheckInsOutPlan(checkInsOutPlan);
                setRouteFactData(combinedData);
                setRoutePlanData(combinedPlanData);
                setManagerName(managerName);
                setCheckIns(checkIns);
            },
            (error) => {
                console.log(
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString(),
                );
            },
        );

    };
    const handleChange = (date) => {
        localStorage.setItem('selectedDate',date.toISOString());
        localStorage.setItem('timeOfSaveSelectedDate',new Date().toISOString());
        setStartDate(date);
    };
    const handleOpenMap = () => {
        navigate('/map', {
            state: {
                managerId: managerId,
                startDate: startDate,
            },
        });
    };


    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Радіус Землі в кілометрах
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceKm = R * c; // Відстань в кілометрах
        const distanceMeters = distanceKm * 1000; // Переведення в метри

        return distanceMeters;
    }

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    return (
        <div>
            <MainMenuComponent/>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <section className="bg-white dark:bg-gray-900">
                        <div className="py-2 mx-auto lg:py-2">
                            <form action="#" className="py-2 mx-auto lg:py-2">
                                <div className="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-4 sm:mb-5">
                                    <div>
                                        <div className="relative font-medium ">

                                            <HeadBodyComponent managerId={managerId} managerName={managerName}
                                                               startDate={startDate} routeName={'/'}
                                                               backText={'Назад'}/>

                                            <div className={'grid gap-4 mb-4 sm:grid-cols-2 sm:gap-4 sm:mb-5'}>
                                                <div className={'col-6'}>
                                                    Дата:
                                                </div>
                                                <div className={'col-6'}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        locale={uk}
                                                        dateFormat="dd.MM.yyyy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>

                    <Tabs>
                        <TabList>
                            <Tab>Деталізація маршруту</Tab>
                            <Tab>Звіт по маршруту</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <button data-modal-target="popup-modal" data-modal-toggle="popup-modal"
                                        className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        type="button" onClick={handleOpenMap}>
                                    Відобразити на карті
                                </button>

                                <div id="popup-modal" tabIndex="-1"
                                     className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center">
                                    <div className="relative w-full max-w-4xl max-h-full">
                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                            <button type="button"
                                                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                                    data-modal-hide="popup-modal">
                                                <svg className="w-3 h-3" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="2"
                                                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                                                </svg>
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 mx-auto sm:px-8 py-4">
                                    <ol className="relative border-l border-gray-200 dark:border-gray-700">

                                        {routeFactData.map((item, index) => (

                                            <li key={index} className="mb-4 ml-4">
                                                <span
                                                    className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                                    {index + 1}
                                                </span>
                                                <div
                                                    className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700">
                                                </div>

                                                <time
                                                    className="relative mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500 -right-2">
                                                    {moment(item.date * 1000).tz('Europe/Kiev').format('YYYY-MM-DD HH:mm:ss')}
                                                </time>


                                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">

                                                    {item.start === 1 && ' Початок маршруту'}
                                                    {item.end === 1 && ' Кінець маршруту'}
                                                    {item.name && (' Check-in')}
                                                    {item.name == null && item.end === 0 && item.start === 0 && (' Корегування маршруту')}
                                                </h3>
                                                <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                                    {item.start === 1 && '0,00 КМ'}
                                                    {item.start === 0 && item.end === 0 && ('Дистанція: ' + (item.distance / 1000).toFixed(3) + ' KM.')}
                                                    {item.end === 1 && ('Дистанція: ' + (item.distance / 1000).toFixed(3) + ' KM.')}
                                                </p>
                                                <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                                    {item.client && (' Клієнт: ' + item.client.name)}

                                                </p>
                                                <p className="text-md font-semibold text-gray-900 dark:text-white">
                                                    {item.name && (item.name)}
                                                </p>
                                            </li>
                                        ))}


                                    </ol>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>

                            <p className="font-extrabold text-gray-800 dark:text-white">Відвідування</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">ТТ
                                План: {routePlanData.length}</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">ТТ
                                Факт: {checkIns.length}</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">ТТ поза маршрутом:
                                {checkInsOutPlan.length}</p>
                            <p className="font-extrabold text-gray-800 dark:text-white">Замовлення: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">UAH: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">USD: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">EUR: 0</p>
                            <p className="font-extrabold text-gray-800 dark:text-white">Інкасація: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">UAH: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">USD: 0</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">EUR: 0</p>
                            <p className="font-extrabold text-gray-800 dark:text-white ">Пробіг</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">План: {(() => {
                                let allDistancePlan = 0;
                                for (let i = 0; i < routePlanData.length; i++) {

                                    allDistancePlan = allDistancePlan + routePlanData[i].distance;
                                    console.log('distance - ' + i,routePlanData[i]);
                                }
                                return (allDistancePlan / 1000).toFixed(3);
                            })()} КМ</p>
                            <p className="pl-2 font-medium text-gray-800 dark:text-white">Факт: {(() => {
                                let allDistanceFact = 0;
                                for (let i = 1; i < routeFactData.length; i++) {

                                    allDistanceFact = allDistanceFact + routeFactData[i].distance;
                                }
                                return (allDistanceFact / 1000).toFixed(3);
                            })()} КМ</p>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>

    );
};
export default BackUpRouteDetailsComponent;
