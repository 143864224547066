import axios from "axios";
import "../config";
import config from "../config";

const API_URL = config.API_ENDPOINT + "/auth/";


class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "login", {
                'email': username,
                'password': password,
                'imei': 'monitor',
                'token_fbc': 'linux'
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        axios.post(API_URL + "logout")
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(API_URL + "signup", {
            username,
            email,
            password
        });
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));

    }
}

export default new AuthService();
