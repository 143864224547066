import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {DirectionsRenderer, GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import UserService from '../../services/userService';
import yellowMarker from '../../assets/yellow.png';
import blueMarker from '../../assets/blue.png';
import MainMenuComponent from '../mainMenuComponent';
import config from '../../config';
import redMarker from "../../assets/red.png";
import HeadBodyComponent from "../general/headBody";
import {getFormattedDate} from "flowbite-react/lib/esm/components/Datepicker/helpers";

const SHOW_FACT_ROUTE_LABEL = 'Відображати фактичний маршрут';
const SHOW_PLAN_ROUTE_LABEL = 'Відображати плановий маршрут';
let overallBounds = {};
const MemoizedRenderDirections = React.memo(RenderDirections);


function RenderDirections(props) {
    let {
        directionsResponse,
        showRoute,
        points,
        map,
        markerIcon,
    } = props;

    const elements = [];

    if (directionsResponse) {
        let overallBounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < directionsResponse.length;
             i++) {
            let route = directionsResponse[i];
            if (route.routes && route.routes[0] && route.routes[0].bounds) {

                let bounds;
                if ('southwest' in route.routes[0].bounds && 'northeast' in route.routes[0].bounds) {
                    bounds = new window.google.maps.LatLngBounds(
                        route.routes[0].bounds.southwest,
                        route.routes[0].bounds.northeast
                    );
                    route.routes[0].bounds = bounds;
                } else {
                    bounds = new window.google.maps.LatLngBounds(
                        { lat: route.routes[0].bounds.Hh.lo, lng: route.routes[0].bounds.Hh.hi },
                        { lat: route.routes[0].bounds.Yh.lo, lng: route.routes[0].bounds.Yh.hi }
                    );
                }

                route.routes[0].overview_path = window.google.maps.geometry.encoding.decodePath(
                    route.routes[0].overview_polyline.points
                );

                route.routes[0].legs = route.routes[0].legs.map(function (leg) {
                    leg.start_location = new window.google.maps.LatLng(
                        leg.start_location.lat,
                        leg.start_location.lng
                    );
                    leg.end_location = new window.google.maps.LatLng(
                        leg.end_location.lat,
                        leg.end_location.lng
                    );
                    leg.steps = leg.steps.map(function (step) {
                        step.path = window.google.maps.geometry.encoding.decodePath(step.polyline.points);
                        step.start_location = new window.google.maps.LatLng(
                            step.start_location.lat,
                            step.start_location.lng
                        );
                        step.end_location = new window.google.maps.LatLng(
                            step.end_location.lat,
                            step.end_location.lng
                        );
                        return step;
                    });
                    return leg;
                });

                const directionsResult = {
                    geocoded_waypoints: route.geocoded_waypoints,
                    routes: route.routes,
                    request: {
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    status: route.status,
                };

                elements.push(
                    <div key={'19' + i}>
                        {showRoute && route && (
                            <DirectionsRenderer
                                key={'directions' + i}
                                directions={directionsResult}
                                options={{
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        strokeColor: markerIcon === yellowMarker ? '#ff2e6d' : '#0070ff',
                                        strokeOpacity: markerIcon === yellowMarker ? 0.5 : 0.6,
                                        strokeWeight: 5,
                                    },
                                }}
                            />
                        )}
                    </div>
                );
                overallBounds.union(route.routes[0].bounds);
            }
        }

        map.current && map.current.fitBounds(overallBounds);

        elements.push(
            <div key="markers">
                {showRoute &&
                    points.map((point, pointIndex) => (
                        <Marker
                            key={'point' + pointIndex}
                            position={{
                                lat: parseFloat(point.latitude),
                                lng: parseFloat(point.longitude),
                            }}
                            label={{
                                text: String(pointIndex + 1),
                                color: markerIcon === yellowMarker ? 'black' : 'white'
                            }}
                            icon={{
                                url: point.name === null ? redMarker : markerIcon,
                                scaledSize: new window.google.maps.Size(25, 35),
                                labelOrigin: new window.google.maps.Point(12.5, 13),
                            }}
                            onClick={(e) => {
                                let content = '';
                                if (point.name) {
                                    content = '<b>' + point.client.name + ' (id:' + point.client.id + ")</b><br>" + point.name + ' (id:' + point.delivery_point_id + ')';
                                } else if (point.start === 1) {
                                    content = '<b>Початок маршруту</b>';
                                } else if (point.end === 1) {
                                    content = '<b>Кінець маршруту</b>';
                                } else {
                                    content = '<b>Коригування маршруту</b>';
                                }
                                content = content + '<br>Координати: ' + point.latitude + " " + point.longitude;

                                const infowindow = new window.google.maps.InfoWindow({
                                    content: content,
                                    ariaLabel: "" + point.latitude + ":" + point.longitude,
                                });
                                const marker = new window.google.maps.Marker({
                                    position: {lat: Number(point.latitude), lng: Number(point.longitude)},
                                    map: map.current,
                                    title: "",
                                    label: {
                                        text: String(pointIndex + 1),
                                        color: markerIcon === yellowMarker ? 'black' : 'white'
                                    }
                                    ,
                                    icon: {
                                        url: point.name === null ? redMarker : markerIcon,
                                        scaledSize: new window.google.maps.Size(25, 35),
                                        labelOrigin: new window.google.maps.Point(12.5, 13),
                                    }
                                });

                                window.google.maps.event.addListener(infowindow, 'closeclick', function () {
                                    marker.setMap(null);
                                });

                                infowindow.open({
                                    anchor: marker,
                                    map: map.current,
                                });
                            }}
                        />
                    ))}
            </div>
        );

    }

    return <>{elements}</>;
}

const MapComponent = () => {
    const location = useLocation();
    const {managerId, startDate} = location.state;
    const [routeFactData, setRouteFactData] = useState([]);
    const [routePlanData, setRoutePlanData] = useState([]);
    const [pointsFact, setPointsFact] = useState([]);
    const [pointsPlan, setPointsPlan] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const mapRef = useRef(null);
    const [directionsResponseFact, setDirectionsResponseFact] = useState(null);
    const [directionsResponsePlan, setDirectionsResponsePlan] = useState(null);
    const [showFactRoute, setShowFactRoute] = useState(true);
    const [showPlanRoute, setShowPlanRoute] = useState(true);
    const mapCenterRef = useRef({lat: 50.441672685980116, lng: 30.554122025148565});
    const [isMarkerClicking, setMarkerClicking] = useState(false);
    const [isMapReady, setIsMapReady] = useState(false);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    let [managerName, setManagerName] = useState('');

    const handleMarkerClick = () => {
        setMarkerClicking(true);
    };
    const handleFactRouteChange = () => {
        setShowFactRoute(!showFactRoute);
        setRerenderFlag(prevFlag => !prevFlag);
    };

    const handlePlanRouteChange = () => {
        setShowPlanRoute(!showPlanRoute);
        setRerenderFlag(prevFlag => !prevFlag);
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
        setIsMapReady(true);
        overallBounds = new window.google.maps.LatLngBounds();

    };

    const handleDirectionsResponse = (response, status, setDirectionsResponse) => {
        if (response.status === 'OK') {
            setDirectionsResponse(response);
        } else {
            console.error('Error fetching directions', response);
        }
    };

    const fetchData = async (managerId, date) => {
        try {
            const timestamp = Math.floor(date.getTime() / 1000);
            const response = await UserService.getRoutes(managerId, timestamp);
            const data = response.data;
            managerName = response.data.manager.name;
            setManagerName(managerName);
            const parseAndSetData = (directionsResponse, setRouteData, setPoints, setDirectionsResponse) => {
                const routeData = JSON.parse(directionsResponse.direction_response);
                const pointsData = data.points[directionsResponse.type];
                setRouteData(routeData);
                setPoints(pointsData);
                setDirectionsResponse(routeData);
            };
            parseAndSetData(data.routes.fact, setRouteFactData, setPointsFact, setDirectionsResponseFact);
            parseAndSetData(data.routes.plan, setRoutePlanData, setPointsPlan, setDirectionsResponsePlan);

        } catch (error) {
            console.error(error.response?.data || error.message || error.toString());
        }
    };

    useEffect(() => {
        fetchData(managerId, startDate);
    }, [managerId, startDate]);

    const libraries = useMemo(() => ['places', 'geometry'], []);
    let formatDate = getFormattedDate('ua',startDate,'dd.MM.yyyy');

    return (
        <div>
            <MainMenuComponent/>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <HeadBodyComponent managerId={managerId} managerName={managerName} startDate={startDate}
                                       routeName={'/route_details/' + managerId} backText={'Назад'}/>
                    <div className="grid gap-4 mb-4 sm:grid-cols-3 sm:gap-4 sm:mb-5">
                        <div>
                            <input type="checkbox" checked={showFactRoute}
                                   onChange={handleFactRouteChange}
                            />
                            <label>{SHOW_FACT_ROUTE_LABEL}</label>
                        </div>
                        <div>
                            <input type="checkbox" checked={showPlanRoute}
                                   onChange={handlePlanRouteChange}
                            />
                            <label>{SHOW_PLAN_ROUTE_LABEL}</label>
                        </div>
                        <div>
                            <label>{formatDate}</label>
                        </div>
                    </div>
                    <div style={{height: '70vh', width: '100%'}}>
                        <LoadScript googleMapsApiKey={config.GOOGLE_MAPS_API_KEY} libraries={libraries}>
                            <GoogleMap
                                mapContainerStyle={{width: '100%', height: '100%'}}
                                center={mapCenterRef.current}
                                onLoad={handleMapLoad}
                                options={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                }}
                            >
                                <MemoizedRenderDirections
                                    key={`directionsPlan${rerenderFlag}`}
                                    directionsResponse={directionsResponsePlan}
                                    showRoute={showPlanRoute}
                                    points={pointsPlan}
                                    setSelectedPoint={setSelectedPoint}
                                    selectedPoint={selectedPoint}
                                    map={mapRef}
                                    markerIcon={yellowMarker}
                                />
                                <MemoizedRenderDirections
                                    key={`directionsFact${rerenderFlag}`}
                                    directionsResponse={directionsResponseFact}
                                    showRoute={showFactRoute}
                                    points={pointsFact}
                                    setSelectedPoint={setSelectedPoint}
                                    selectedPoint={selectedPoint}
                                    map={mapRef}
                                    markerIcon={blueMarker}
                                />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapComponent;
