import React, { Component } from "react";
import {Routes, Route, Navigate} from "react-router-dom";

import "./App.css";
import "./flowbite.min.css";
import AuthService from "./services/authService";
import Login from "./components/auth/loginComponent";
import Home from "./components/routes/homeComponent";
import RouteDetails from "./components/routes/routeDetailsComponent";
import EventBus from "./common/eventBus";
import MapComponent from "./components/routes/mapComponent";
import MapComponentOld from "./components/routes/BackUpMapComponent";
import DistanceReport from "./components/routes/DistanceReportComponent";
import ListDistanceReport from "./components/routes/ListDistanceReportComponent";
import Settings from "./components/routes/SettingsComponent";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    // const user = AuthService.getCurrentUser();
    //
    // if (user) {
    //   this.setState({
    //     currentUser: user,
    //     showModeratorBoard: Array.isArray(user.roles) && user.roles.includes("ROLE_MODERATOR"),
    //     showAdminBoard: Array.isArray(user.roles) && user.roles.includes("ROLE_ADMIN"),
    //   });
    // }
    //
    // EventBus.on("logout", () => {
    //   this.logOut();
    // });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { showModeratorBoard, showAdminBoard } = this.state;
    const user = AuthService.getCurrentUser();

    const privateRoutes = [
      { path: "/", component: Home },
      { path: "/home", component: Home },
      { path: "/route_details/:managerId", component: RouteDetails },
      { path: "/distance_report/:managerId", component: DistanceReport },
      { path: "/map", component: MapComponent },
      { path: "/list_distance_report", component: ListDistanceReport},
      { path: "/map_old", component: MapComponentOld },
      { path: "/settings", component: Settings},
    ];

    return (
        <div>


          <div>
            <Routes>
              {privateRoutes.map(({ path, component: Component }) => (
                  <Route
                      key={path}
                      path={path}
                      element={
                        user ? <Component /> : <Navigate to="/login" />
                      }
                  />
              ))}
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
    );
  }
}

export default App;
