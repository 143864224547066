import axios from 'axios';
import authHeader from './authHeader';
import config from '../config';

const API_URL = config.API_ENDPOINT + '/monitor/';


class UserService {

    getManagerDataForDate(date = 0) {
        if (date === 0) {
            const currentDate = new Date();
            date = currentDate.getTime();
        }

        return axios.get(API_URL + 'managers/' + date,{headers: authHeader()});
    }

    getDepartments() {
        return axios.get(API_URL + 'department',{headers: authHeader()});
    }

    saveRouteData(data) {
        return axios.post(API_URL + 'save-route-data',{headers: authHeader(), data: data});
    }

    getRoutes(managerId,date=0){
        if (date === 0) {
            const currentDate = new Date();
            date = currentDate.getTime();
        }
        return axios.get(API_URL+'routes/'+managerId+'/'+date+'', {headers: authHeader()});
    }

    getDataReport(managerId,startDate=0, endDate=0){
        return axios.get(API_URL+'routes/'+managerId+'/'+startDate+'/'+endDate+'', {headers: authHeader()});
    }
    getRouteFact(managerId, date = 0){
        if (date === 0) {
            const currentDate = new Date();
            date = currentDate.getTime();
        }
        return axios.get(API_URL+'manager_route_fact/'+managerId+'/'+date+'', {headers: authHeader()});
    }

    getRoutePlan(managerId, date = 0){
        if (date === 0) {
            const currentDate = new Date();
            date = currentDate.getTime();
        }
        return axios.get(API_URL+'manager_route_plan/'+managerId+'/'+date+'', {headers: authHeader()});
    }

    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'user', {headers: authHeader()});
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'mod', {headers: authHeader()});
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', {headers: authHeader()});
    }
}

export default new UserService();
